const resorts = {
  resort: [
    {
      name: "Royal Curaçao",
      city: "St. Santa Barbara",
      country: "",
      rstCode: "SCR",
      link: "/en/dunns-river/",
      featured: false,
      new: true,
      ibmClickTag: "HP Resorts Carousel-_-Curacao-_-N/A",
      caption: "The beautiful Dutch-inspired island of Curaçao",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/sandals-curacao.jpg",
    },
    {
      name: "Grenada",
      city: "St. George&#x27;s",
      country: "Grenada",
      rstCode: "SLS",
      link: "/en/royal-curacao/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Grenada-_-N/A",
      caption: "The Sandals of Tomorrow",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/sandals-grenada.jpg",
    },
    {
      name: "Grenada",
      city: "St. George&#x27;s",
      country: "Grenada",
      rstCode: "SLS",
      link: "/en/sandals-grenada/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Grenada-_-N/A",
      caption: "The Sandals of Tomorrow",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/sandals-grenada.jpg",
    },
    {
      name: "South Coast",
      city: "Whitehouse",
      country: "Jamaica",
      rstCode: "SWH",
      link: "/en/south-coast/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-South Coast-_-N/A",
      caption: "All Beachfront Rooms Guaranteed!",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/south-coast.jpg",
    },
    {
      name: "Grande Antigua",
      city: "St. John&#x27;s",
      country: "Antigua",
      rstCode: "SAT",
      link: "/en/grande-antigua/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Grande Antigua-_-N/A",
      caption: "Two Distinct Resort Experiences On Antigua&#x27;s Best Beach",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/grande-antigua.jpg",
    },
    {
      name: "Royal Caribbean",
      city: "Montego Bay",
      country: "Jamaica",
      rstCode: "SRC",
      link: "/en/royal-caribbean/",
      featured: true,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Royal Caribbean-_-N/A",
      caption: "A British Colonial Estate with an Exotic Offshore Island",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/royal-caribbean.jpg",
    },
    {
      name: "Montego Bay",
      city: "Montego Bay",
      country: "Jamaica",
      rstCode: "SMB",
      link: "/en/montego-bay/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Montego Bay-_-N/A",
      caption: "The Beachfront Resort Where the Party Never Stops",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/montego-bay.jpg",
    },
    {
      name: "Negril",
      city: "Negril",
      country: "Jamaica",
      rstCode: "SNG",
      link: "/en/negril/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Negril-_-N/A",
      caption: "On Jamaica&#x27;s Most Famous Beach",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/sandals-negril.jpg",
    },
    {
      name: "Halcyon Beach",
      city: "Castries",
      country: "St. Lucia",
      rstCode: "SHC",
      link: "/en/halcyon-beach/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Halcyon Beach-_-N/A",
      caption: "A True Garden of Eden by a Tranquil Sea",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/halcyon-beach.jpg",
    },
    {
      name: "Barbados",
      city: "St. Lawrence Gap",
      country: "Barbados",
      rstCode: "SBD",
      link: "/en/sandals-barbados/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Barbados-_-N/A",
      caption: "Where Exclusive is Always Inclusive",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/sandals-barbados.jpg",
    },
    {
      name: "Ochi",
      city: "Ocho Rios",
      country: "Jamaica",
      rstCode: "SGO",
      link: "/en/ochi/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Ochi-_-N/A",
      caption: "Two Distinct Resort Experiences Surrounded by Natural Jamaica",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/ochi.jpg",
    },
    {
      name: "Royal Barbados",
      city: "St. Lawrence Gap",
      country: "Barbados",
      rstCode: "SBR",
      link: "/en/royal-barbados/",
      featured: false,
      new: true,
      ibmClickTag: "HP Resorts Carousel-_-Royal Barbados-_-N/A",
      caption: "The Most Exclusive All&ndash;Inclusive Ever",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/royal-barbados.jpg",
    },
    {
      name: "Grande St. Lucian",
      city: "Gros-Islet",
      country: "St. Lucia",
      rstCode: "SGL",
      link: "/en/grande-st-lucian/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Grande St. Lucian-_-N/A",
      caption: "Grand Views on its Own Peninsula",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/grande-st-lucian.jpg",
    },
    {
      name: "Royal Bahamian",
      city: "Nassau",
      country: "Bahamas",
      rstCode: "SRB",
      link: "/en/royal-bahamian/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Royal Bahamian-_-N/A",
      caption: "A Sophisticated European Resort with its Own Offshore Island",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/royal-bahamian.jpg",
    },
    {
      name: "Regency La Toc",
      city: "Castries",
      country: "St. Lucia",
      rstCode: "SLU",
      link: "/en/regency-la-toc/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Regency La Toc-_-N/A",
      caption: "A Glamorous Golf Resort",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/regency-la-toc.jpg",
    },
    {
      name: "Royal Plantation",
      city: "Ocho Rios",
      country: "Jamaica",
      rstCode: "BRP",
      link: "/en/royal-plantation/",
      featured: false,
      new: false,
      ibmClickTag: "HP Resorts Carousel-_-Royal Plantation-_-N/A",
      caption: "Sandals Only All-Butler, All-Suite Resort",
      img: "//cdn.sandals.com/sandals/v12/images/home/resorts/royal-plantation.jpg",
    },
  ],
};

module.exports = resorts;
