import homeTopSlides from '~/js/views/root/includes/home-top-slides';
const topSlides = [];
const topSliderWrap = document.querySelector('#hero-slider .slide-wrap');
let counter = 0;
const caption = document.getElementById('caption');
const playPause = document.getElementById('playPause');
const $sliderTop = $('#hero-slider .slide-wrap');
const instagramDots = require('~/js/components/DotsNav');
// let isThereVideo = false;
const cdnURL = '//cdn.sandals.com/sandals/v12';

const homepageVideo = () => {
    const sliderPage = document.querySelector('#homepage-slide-video');
    const videoWrap = document.createElement('video');

    videoWrap.className = 'homepage-vid';
    videoWrap.id = 'homepage-video';
    videoWrap.preload = 'none';
    videoWrap.muted =  true;
    videoWrap.volume = 0;
    videoWrap.playsInline = true;
    videoWrap.loop = true;

    if(window.innerWidth > 767) {
        videoWrap.innerHTML = `
            <source src="${cdnURL}/images/home/top-slides/homepage-video/slide-20-opt.webm" type="video/webm"/>
            <source src="${cdnURL}/images/home/top-slides/homepage-video/slide-20-opt.mp4" type="video/mp4">
        `;
    } else {
        videoWrap.innerHTML = `
        <source src="${cdnURL}/images/home/top-slides/homepage-video/slide-20-opt.webm" type="video/webm"/>
        <source src="${cdnURL}/images/home/top-slides/homepage-video/slide-20-opt.mp4" type="video/mp4"ß/>
    `;
    }

    sliderPage.append(videoWrap);

    const headerVideo = document.querySelector('video.homepage-vid');

    setTimeout(() => {
        $('.homepage-vid').addClass('playing');
        if ($('#homepage-slide-video video').hasClass('playing')) {
            $('#homepage-slide-video picture').hide();
        } else {
            $('#homepage-slide-video picture').show();
        }
        setTimeout(() => {
            headerVideo.play();
        }, 400);
    }, 4000);
};

const slickSlider = () => {

    const $statusSliderTop = $('#hero-slider .js-slick-slider-top-counter');

    $sliderTop.on('init', (event, slick, currentSlide) => {
        const i = (currentSlide ? currentSlide : 0);

        const slideNum = (currentSlide ? currentSlide : 0) + 1;
        // const i = (currentSlide ? currentSlide : 0) + 1;
        const dotsWrap = document.querySelector('#hero-slider .slick-dots');
        const dots = Array.from(dotsWrap.querySelectorAll('li'));

        window.onload = () => {
            // caption.innerHTML = homeTopSlides.slides[i].caption;
            caption.innerHTML = homeTopSlides.slides[0].caption;
            const img = $sliderTop.find(`div[data-slick-index="${i}"`).find('.slider-item-inner picture img');

            img.attr('src', img.attr('data-src'));
        };

        // caption.innerHTML = homeTopSlides.slides[0].caption;
        // const img = $sliderTop.find('.fivestar-slide').find('.slider-item-inner picture img');

        // img.attr('src', img.attr('data-src'));

        $statusSliderTop.text(`${slideNum}/${slick.slideCount}`);
        // $statusSliderTop.text(`${i}/${slick.slideCount}`);

        if (dots.length > 5) {
            dots.forEach((dot, index) => {
                if (index <= 2) {
                    dot.classList.add('large');
                } else if (index === 3) {
                    dot.classList.add('semi');
                } else if (index === 4) {
                    dot.classList.add('small');
                } else {
                    dot.classList.add('none');
                }
            });
            instagramDots(dotsWrap, $sliderTop);
        }
    });

    $sliderTop.on('reInit afterChange', (event, slick, currentSlide) => {
        const i = (currentSlide ? currentSlide : 0) + 1;

        $statusSliderTop.text(`${i}/${slick.slideCount}`);
    });

    // Find is there's a video on the first slide.
    // Set playback speed at the video duration and then change it normal for other slides.
    // So video can play through the first time
    $sliderTop.slick({
        centerMode: true,
        centerPadding: '0',
        cssEase: 'ease-out',
        fade: false,
        dots: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 8500,
        prevArrow: $('#hero-slider .js-prev-slide-in-slider-top'),
        nextArrow: $('#hero-slider .js-next-slide-in-slider-top'),
        touchThreshold: 30,
    });

    // const vid = document.querySelector('div[data-slick-index="0"]').querySelector('.slider-item-inner video');

    // isThereVideo = vid === 'undefined' ? false : true;

    // if( isThereVideo ) {
    //     $sliderTop.slick('slickSetOption', 'autoplaySpeed', (60000) * 10);

    //     vid.onended = function() {
    //         $sliderTop.slick('slickSetOption', 'autoplaySpeed', 8000);
    //         vid.loop = true;
    //         vid.play();
    //         if (!/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Edge|MSIE \d|Trident.*rv:/i.test(navigator.userAgent)) {
    //             $sliderTop.slick('slickGoTo', 1);
    //         }
    //     };
    // }

    $sliderTop.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        caption.innerHTML = homeTopSlides.slides[nextSlide].caption;
        if (nextSlide === 0 ) {
            return false;
        }
        if (nextSlide <= slick.slideCount - 1) {
            const srcs = $sliderTop.find(`div[data-slick-index='${nextSlide}']`).find('.slider-item-inner picture source');
            const img = $sliderTop.find(`div[data-slick-index='${nextSlide}']`).find('.slider-item-inner picture img');

            // srcs.each(function() {
            srcs.each( () => {
                const imgSrc = $(this).attr('data-srcset');

                $(this).attr('srcset', imgSrc);
            });
            img.attr('src', img.attr('data-src'));
        }
        if (nextSlide <= slick.slideCount - 2) {
            const srcs = $sliderTop.find(`div[data-slick-index='${nextSlide + 1}']`).find('.slider-item-inner picture source');
            const img = $sliderTop.find(`div[data-slick-index='${nextSlide + 1}']`).find('.slider-item-inner picture img');

            // srcs.each(function() {
            srcs.each( () => {
                const imgSrc = $(this).attr('data-srcset');

                $(this).attr('srcset', imgSrc);
            });
            img.attr('src', img.attr('data-src'));
        }
        if (nextSlide <= slick.slideCount - 3) {
            const srcs = $sliderTop.find(`div[data-slick-index='${nextSlide + 2}']`).find('.slider-item-inner picture source');
            const img = $sliderTop.find(`div[data-slick-index='${nextSlide + 2}']`).find('.slider-item-inner picture img');

            // srcs.each(function() {
            srcs.each( () => {
                const imgSrc = $(this).attr('data-srcset');

                $(this).attr('srcset', imgSrc);
            });
            img.attr('src', img.attr('data-src'));
        }

        return true;
    });
};


// ACCESSIBILITY
const $sliderLinks = $('#hero-slider a');

// $sliderLinks.on('focus', function sliderFocusNav(e) {
$sliderLinks.on('focus',  (e) => {
    e.preventDefault();

    let sliderHasFocus = false;

    if ($sliderLinks.is(':focus')) {
        sliderHasFocus = true;
    }

    if (sliderHasFocus) {
        $sliderTop.slick('slickPause');
        $(playPause).addClass('play').removeClass('pause');
    }
});

$(playPause).click((event) => {
    if (event.target.classList.contains('pause')) {
        $sliderTop.slick('slickPause');
        $(playPause).removeClass('pause').addClass('play');
        $(playPause).attr('aria-label', 'Play carousel');
    } else {
        $sliderTop.slick('slickPlay');
        $(playPause).removeClass('play').addClass('pause');
        $(playPause).attr('aria-label', 'Pause carousel');
    }
});

window.onload = () => {

    homeTopSlides.slides.forEach((slide, index, array) => {
        const linkText = slide.anchorText !== '' ? slide.anchorText : 'NOW OPEN';

        if (index !== 0) {
            if (!slide.customizeLayout) {
                topSlides.push(`
                    <div class='slider-item homepage-video-slide'>
                        <div class='slider-item-inner' id="homepage-slide-video">
                            <div class='container'>
                                <div class='row'>
                                    <div class='col-xs-12 blue-bar ${!slide.leftAligned && 'right'}'>
                                        ${slide.topText}
                                        ${slide.bottomText}
                                        ${slide.link !== '' ? `<a class="learn-more" href=${slide.link} data-ibm=${slide.ibmClickTag}> ${linkText}</a>` : ''}
                                        ${slide.additionalElements}
                                    </div>
                                </div>
                            </div>
                            ${!slide.video ? `<picture> <source media='(max-width: 767px)' data-srcset=${slide.imgMobile}><source media='(min-width: 768px)' data-srcset=${slide.imgDesktop}><img src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' data-src=${slide.imgDesktop}></picture>` : ''}
                            ${slide.video ? `<video autoplay loop muted playsinline> <source type='video/mp4' src=${slide.videoLinkMP4}> <source type='video/webm' src=${slide.videoLinkWebM}> <img src=${slide.imgMobile} /></video>` : ''}
                        </div>
                    </div>
                `);
            } else {
                topSlides.push(`
                        <div class='slider-item ${slide.idName}'>
                    <a href='${slide.link}'>
                            <div class='slider-item-inner'>
                            ${slide.additionalElements}
                            <picture>
                                <source media='(max-width: 767px)' data-srcset=${slide.imgMobile}>
                                <source media='(min-width: 768px)' data-srcset=${slide.imgDesktop}>
                                    <img src=${window.innerWidth > 768 ? slide.imgDesktop : slide.imgMobile} data-src=${window.innerWidth > 768 ? slide.imgDesktop : slide.imgMobile} id="slideImage">
                            </picture>
                            </div>
                    </a>
                        </div>
                `);
            }
        }

        counter++;

        if (counter === array.length) {
            $(topSliderWrap).append(topSlides.join(''));
            slickSlider();

            if (window.innerWidth > 767) {
                $sliderTop.slick('slickPlay');
            }
        }
    });
    homepageVideo();

};
