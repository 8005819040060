import resorts from "~/js/views/root/includes/resort-slides";
const resortSlides = [];
const resortSliderWrap = document.querySelector("#resortSlider .slide-wrap");
let counter = 0;
const $sliderTop = $("#resortSlider .slide-wrap");
const featuredResort = document.getElementById("featuredResort");
const instagramDots = require("~/js/components/NavDots");

const slickSlider = () => {
  const $statusSliderTop = $("#resortSlideWrap .js-slick-slider-top-counter");

  $sliderTop.on("init", (event, slick, currentSlide, nextSlide) => {
    const dotsWrap = document.querySelector("#resortSlideWrap .slick-dots");
    const dots = Array.from(dotsWrap.querySelectorAll("li"));

    $sliderTop
      .find(`li[data-slick-index='${nextSlide + 1}']`)
      .find(".slider-item-inner")
      .css(
        "background-image",
        `url(${$sliderTop
          .find(`li[data-slick-index='${nextSlide}']`)
          .find(".slider-item-inner")
          .data("bg")})`
      );

    if (dots.length > 5) {
      dots.forEach((dot, index) => {
        if (index <= 2) {
          dot.classList.add("large");
        } else if (index === 3) {
          dot.classList.add("semi");
        } else if (index === 4) {
          dot.classList.add("small");
        } else {
          dot.classList.add("none");
        }
      });
      instagramDots(dotsWrap, $sliderTop);
    }
  });

  $sliderTop.on("init reInit afterChange", (event, slick, currentSlide) => {
    const i = (currentSlide ? currentSlide : 0) + 1;

    $statusSliderTop.text(`${i}/${slick.slideCount}`);
  });

  $sliderTop.slick({
    centerMode: true,
    centerPadding: "0",
    cssEase: "ease-out",
    fade: false,
    dots: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 400,
    autoplay: false,
    autoplaySpeed: 5000,
    prevArrow: $("#resortSlideWrap .js-prev-slide-in-slider-top"),
    nextArrow: $("#resortSlideWrap .js-next-slide-in-slider-top"),
    touchThreshold: 30,
    lazyLoad: "progressive",
  });

  $sliderTop.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
    $sliderTop
      .find(`div[data-slick-index='${nextSlide}']`)
      .find(".slider-item-inner")
      .lazy({ attribute: "data-bg" });
    $sliderTop
      .find(`div[data-slick-index='${nextSlide + 1}']`)
      .find(".slider-item-inner")
      .lazy({ attribute: "data-bg" });
    featuredResort.setAttribute("href", resorts.resort[nextSlide].link);
  });
};

resorts.resort.forEach((resort, index, array) => {
  if (index !== 1) {
    const rstCode = resort.rstCode;
    let resortlabel = "";

    if (rstCode === "SDR") {
      resortlabel = '<span class="label">All New</span> ';
    }
    if (rstCode === "SCR") {
      resortlabel = '<span class="label">Now Open</span> ';
    }

    resortSlides.push(`
            <div class='slider-item'>
                <div class='slider-item-inner' data-bg='${resort.img}'>
           <div class='container relative height-100'>
            <div class='row'>
              <ul class='resort-title col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2'>
                <li>
                  ${resortlabel}
                  <h2>Sandals ${resort.name}</h2>
                  <h3>${resort.city}, ${resort.country}</h3>
                </li>
              </ul>
              <div class='floating-box col-xs-12 col-md-8 col-md-offset-2'>
                                <p>${resort.caption}</p>
              </div>
            </div>
          </div>
                </div>
            </div>
        `);
  }
  counter++;
  if (counter === array.length) {
    $(resortSliderWrap).append(resortSlides.join(""));
    slickSlider();

    if (window.innerWidth > 767) {
      $sliderTop.slick("slickPlay");
    }
  }
});
