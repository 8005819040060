import inclusions from '~/js/views/root/includes/inclusion-slides';
const inclusionSlides = [];
let counter = 0;
const $sliderTop = $('#qualityInclusionsSlider .slide-wrap');

function preloadSlide($slider, currentSlide, amount) {
    const $imagePrevObj = $slider.find(`div[data-slick-index="${currentSlide - amount}"]`).find('.slide-image');
    const $imageNextObj = $slider.find(`div[data-slick-index="${currentSlide + amount}"]`).find('.slide-image');
    const imagePrevSrc = $imagePrevObj.data('lazy');
    const imageNextSrc = $imageNextObj.data('lazy');

    if (imagePrevSrc !== $imagePrevObj.attr('src')) {
        $imagePrevObj.attr('src', imagePrevSrc);
    }
    if (imageNextSrc !== $imageNextObj.attr('src')) {
        $imageNextObj.attr('src', imageNextSrc);
    }
}

const slickSlider = () => {
    $sliderTop.on('init', (event, slick) => {
        $(slick.$slides[2]).find('.slide-image').attr('src', $(slick.$slides[2]).find('.slide-image').data('lazy'));
    });

    $sliderTop.slick({
        centerMode: true,
        cssEase: 'ease',
        lazyLoad: 'ondemand',
        fade: false,
        infinite: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 6000,
        variableWidth: true,
        slidesToScroll: 1,
        prevArrow: $('#qualityInclusionsSlider .js-prev-slide-in-slider-top'),
        nextArrow: $('#qualityInclusionsSlider .js-next-slide-in-slider-top'),
        responsive: [{
            breakpoint: 767,
            settings: {
                centerMode: false
            }
        }]
    });

    $sliderTop.on('beforeChange', (event, slick, currentSlide) => {
        preloadSlide($sliderTop, currentSlide, 2);
        if (window.innerWidth >= 768) {
            preloadSlide($sliderTop, currentSlide, 3);
        }
    });

    $sliderTop.on('afterChange', (event, slick, currentSlide) => {
        preloadSlide($sliderTop, currentSlide, 1);
        if (window.innerWidth >= 768) {
            preloadSlide($sliderTop, currentSlide, 2);
        }
    });
};

inclusions.inclusion.forEach((inclusion, index, array) => {
    const cond = (inclusion.included !== '');
    const logo = (inclusion.logo !== '');

    inclusionSlides.push(`
        <div class='slider-item'>
            <a href='${inclusion.link}'>
                <div class='slider-item-inner'>
                    ${logo ? `<img src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' data-lazy='${inclusion.logo}' class='logo'>` : ''}
                    <img class="slide-image" src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' data-lazy='${inclusion.img}' />
                </div>
                <div class='text-box'>
                    ${cond ? `<div class=included>${inclusion.included}</div>` : ''}
                    <p>${inclusion.top}</p>
                    <h3>${inclusion.bottom}</h3>
                </div>
            </a>
        </div>
        `);
    counter++;
    if (counter === array.length) {
        $sliderTop.append(inclusionSlides.join(''));
        slickSlider();
    }
});
