import 'slick-carousel';
import '~/js/views/root/includes/home-top-slider';
import '~/js/views/root/includes/resort-slider';
import '~/js/views/root/includes/suite-slider';
import '~/js/views/root/includes/inclusion-slider';
import '~/js/views/root/includes/new-at-sandals-slider';
import '~/js/modules/countdown';
// import QuickQuote from '~/js/components/QuickQuote';
console.log('test');

const list = document.getElementById('list');
const marginTarget = document.querySelector('#newSandals .sectionCopy');
const datePickerElements = document.querySelector('.rct-qq');
const whyBookSandals = document.querySelector('.detail-book');
const detailClose = document.getElementById('detailClose');
const sliderWraps = Array.from(document.getElementsByClassName('pausedSlider'));

const isInViewport = elem => {
    const bounding = elem.getBoundingClientRect();

    return (
        bounding.top >= -650 &&
        bounding.left >= 0 &&
        bounding.bottom <=
            (window.innerHeight + 650 ||
                document.documentElement.clientHeight + 650) &&
        bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
};

const playSlide = _.debounce(() => {
    if (window.innerWidth > 767) {
        sliderWraps.forEach(slider => {
            if (
                isInViewport(slider) &&
                slider.getAttribute('data-played') === 'false'
            ) {
                slider.setAttribute('data-played', 'true');
                $(slider)
                    .find("div[data-slick-index='1']")
                    .find('.slider-item-inner')
                    .Lazy({ attribute: 'data-bg' });
                $(slider).removeClass('pausedSlider');
                $(slider).slick('slickPlay');
                if (slider.classList.contains('carouselSlider')) {
                    const name = slider.parentElement.getAttribute('id');

                    $(`#${name} .js-lazy`).unveil();
                }
            }
        });
    }
}, 300);

const adjustMargin = _.debounce(() => {
    marginTarget.style.marginTop = `${list.offsetHeight / 3}px`;
    list.style.marginTop = `-${list.offsetHeight / 3}px`;
}, 1000);

marginTarget.style.marginTop = `${list.offsetHeight / 3}px`;
list.style.marginTop = `-${list.offsetHeight / 3}px`;

window.addEventListener('scroll', playSlide);

window.addEventListener('resize', adjustMargin);

// ReactDOM.render(
//     <QuickQuote
//         schedulerModeIndex={4}
//         hasCountries={false}
//         quoteButtonText='Rates &amp; Availability'
//     />,
//     datePickerElements
// );

document.onclick = event => {
    const target = event.target;

    if (!Element.prototype.matches) {
        if (target.webkitMatchesSelector) {
            target.matches = target.webkitMatchesSelector;
        }

        if (target.msMatchesSelector) {
            target.matches = target.msMatchesSelector;
        }

        if (target.mozMatchesSelector) {
            target.matches = target.mozMatchesSelector;
        }
    }
    if (target.matches('.whyBook, .whyBook *')) {
        if (!whyBookSandals.classList.contains('show')) {
            whyBookSandals.classList.add('show');
        }
    }

    if (target === detailClose) {
        whyBookSandals.classList.remove('show');
    }
};
