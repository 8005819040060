import suites from '~/js/views/root/includes/suite-slides';
const suiteSlides = [];
let counter = 0;
const $sliderTop = $('#suiteSectionSlider .slide-wrap');
const suiteInfo = document.getElementById('suite-info');

const slickSlider = () => {

    const $statusSliderTop = $('#suiteSectionSlider .js-slick-slider-top-counter');

    $sliderTop.on('init reInit afterChange', (event, slick, currentSlide) => {
        const i = (currentSlide ? currentSlide : 0) + 1;

        $statusSliderTop.text(`${i}/${slick.slideCount}`);
    });

    $sliderTop.slick({
        centerMode: true,
        centerPadding: '0',
        cssEase: 'linear',
        fade: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 4000,
        prevArrow: $('#suiteSectionSlider .js-prev-slide-in-slider-top'),
        nextArrow: $('#suiteSectionSlider .js-next-slide-in-slider-top')
    });

    $sliderTop.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        $sliderTop.find(`div[data-slick-index='${nextSlide}']`).find('.slider-item-inner').Lazy({ attribute: 'data-bg' });
        $sliderTop.find(`div[data-slick-index='${nextSlide + 1}']`).find('.slider-item-inner').Lazy({ attribute: 'data-bg' });
        suiteInfo.querySelector('strong').innerHTML = suites.suite[nextSlide].suite;
        suiteInfo.querySelector('a').setAttribute('href', `${suites.suite[nextSlide].link}rooms-suites/`);
        suiteInfo.querySelector('span').innerHTML = suites.suite[nextSlide].resort;
    });
};

suites.suite.forEach((suite, index, array) => {
    if (index !== 0) {
        suiteSlides.push(`
            <div class='slider-item'>
                <div class='slider-item-inner' data-bg='${suite.img}'></div>
            </div>
        `);
    }
    counter++;
    if (counter === array.length) {
        $sliderTop.append(suiteSlides.join(''));
        slickSlider();
    }
});
