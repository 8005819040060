const homeTopSlides = {
    slides: [
        {
            caption: 'Find your Caribbean here',
            link: '/en/all-inclusive/',
			imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_FindYourCaibbeanHere_Mobile_EU.jpg',
            imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_FindYourCaibbeanHere_Desktop_EU.jpg',
			topText: '',
			bottomText: '',
			customizeLayout: true,
			additionalElements: '',
			idName: 'firstSlide',
			video: false,
			videoLinkMP4: '',
			videoLinkWebM: '',
			leftAligned: true,
			anchorText: '',
        },
        {
            caption: 'Sandals Foundation - Empower 15',
            link: '/en/sandals-foundation-the-power-of-15/',
			imgMobile: '//cdn.sandals.com/sandals/v12/images/general/about/sandals-foundation/fifteen-anniversary/sf-banner-nocopy-mobile.jpg',
            imgDesktop: '//cdn.sandals.com/sandals/v13/images/EN/home/hero-slider/images/slide-50-desktop.jpg',
			topText: '',
			bottomText: '',
			customizeLayout: true,
			additionalElements: '',
			idName: 'Sandals-Foundation-15',
			video: false,
			videoLinkMP4: '',
			videoLinkWebM: '',
			leftAligned: true,
			anchorText: '',
        },
        {
            caption: 'BOOK NOW',
            link: '/en/weddings/',
			imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU_Detination_Wedding_Banner_Mobile-Oct23.jpg',
            imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU_Detination_Wedding_Banner_Desktop-Oct23.jpg',
			topText: '',
			bottomText: '',
			customizeLayout: true,
			additionalElements: '',
			idName: 'Detination-Wedding-slide',
			video: false,
			videoLinkMP4: '',
			videoLinkWebM: '',
			leftAligned: true,
			anchorText: '',
        },
        {
            caption:"Now, completely reimagined, Sandals Dunn's River returns to the Sandals Family",
            link: '/en/dunns-river/',
            ibmClickTag: "'HP Top Carousel-_-Love is All You Need-_-N/A'",
            imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/homepage-video/banner-bg-m.jpg',
            imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/homepage-video/banner-bg-d.jpg',
            topText: "<p class='large-font'>Sandals DUNN'S RIVER</p>",
            bottomText: "<p class='small-font bottom-font'>LET LIFE FLOW</p>",
            customizeLayout: false,
            additionalElements: '',
            idName: 'homepage-video-slide',
            video: false,
            videoLinkMP4: '//cdn.sandals.com/sandals/v13/images/EN/home/hero-slider/videos/slide-20-opt.mp4',
            videoLinkWebM: '//cdn.sandals.com/sandals/v13/images/EN/home/hero-slider/videos/slide-20-opt.webm',
            leftAligned: true,
            anchorText: '',
        },
        // {
        //     caption: 'The All-New Rondovals & Beachfront Suites at Sandals Halcyon Beach',
        //     link: '/en/halcyon-beach/',
        //     ibmClickTag: "'HP Top Carousel-_-Curacao Now Open for Booking-_-N/A'",
        //     imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_Halcyon_Mobile.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_Halcyon_HpBanner_Desktop.jpg',
        //     topText: '',
        //     bottomText: '',
        //     customizeLayout: true,
        //     additionalElements: '',
        //     idName: 'TCA-slide',
        //     video: false,
        //     videoLinkMP4: '',
        //     videoLinkWebM: '',
        //     leftAligned: true,
        //     anchorText: '',
        // },
        // {
        //     caption: 'Nature and luxury go hand-in-hand at Sandals Dunn’s River – the spectacular new resort in the heart of Ocho Rios, Jamaica.',
        //     link: '/en/dunns-river/',
        //     ibmClickTag: "'HP Top Carousel-_-Curacao Now Open for Booking-_-N/A'",
        //     imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_SDR_HpBanner_Mobile.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_SDR_HpBanner_Desktop.jpg',
        //     topText: '',
        //     bottomText: '',
        //     customizeLayout: true,
        //     additionalElements: '',
        //     idName: 'sdr-slide',
        //     video: false,
        //     videoLinkMP4: '',
        //     videoLinkWebM: '',
        //     leftAligned: true,
        //     anchorText: '',
        // },
        {
            caption: 'The beautiful Dutch-inspired island of Curaçao is home to Sandals newest all-inclusive resort.',
            link: '/en/royal-curacao/',
            ibmClickTag: "'HP Top Carousel-_-Curacao Now Open for Booking-_-N/A'",
            imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_Curacao_HpBanner_Mobile.jpg',
            imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_Curacao_HpBanner_Desktop.jpg',
            topText: '',
            bottomText: '',
            customizeLayout: true,
            additionalElements: '',
            idName: 'scr-slide',
            video: false,
            videoLinkMP4: '',
            videoLinkWebM: '',
            leftAligned: true,
            anchorText: '',
        },

        // {
        //     caption: 'Sandals - The World’s Only 5-Star Luxury Included<sup>&reg;</sup> Resorts',
        //     link: '/en/all-inclusive/',
        //     ibmClickTag: "'HP Top Carousel-_-Love is All You Need-_-N/A'",
        //     imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/homepage-video/banner-bg-m.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/homepage-video/banner-bg-d.jpg',
        //     topText: "<p class='large-font'>LOVE IS ALL YOU NEED.</p>",
        //     bottomText: "<p class='small-font bottom-font'>EVERYTHING ELSE IS INCLUDED.</p>",
        //     customizeLayout: false,
        //     additionalElements: '',
        //     idName: 'homepage-video-slide',
        //     video: false,
        //     videoLinkMP4: '//cdn.sandals.com/sandals/v12/images/home/top-slides/homepage-video/Sandals_Homepage_love_is_allyouneed_video.mp4',
        //     videoLinkWebM: '//cdn.sandals.com/sandals/v12/images/home/top-slides/homepage-video/Sandals_Homepage_love_is_allyouneed_video.webm',
        //     leftAligned: true,
        //     anchorText: '',
        // },
        // {
        //     caption: 'Wew are proud to announce three new resorts coming to the north coast of Jamaica.',
        //     link: '/en/jamaica/opening-new-resorts/',
		// 	imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/news/3NewResorts-Jamaica-mobile.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/uk-slides/3NewResorts-Jamaica.jpg',
		// 	topText: '',
		// 	bottomText: '',
		// 	customizeLayout: true,
		// 	additionalElements: '',
		// 	idName: 'thirdSlide',
		// 	video: false,
		// 	videoLinkMP4: '',
		// 	videoLinkWebM: '',
		// 	leftAligned: true,
		// 	anchorText: '',
        // },
        // {
		// 	caption: 'The Sandals Sale Now On',
		// 	link: '/en/specials/current-offers/',
		// 	ibmClickTag: "'HP Top Carousel-_-Curacao Now Open for Booking-_-N/A'",
		// 	imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_UK_SaleExtension_Homepage_banner_Desktop.jpg',
		// 	imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_UK_SaleExtension_Homepage_banner_Desktop.jpg',
		// 	topText: '',
		// 	bottomText: '',
		// 	customizeLayout: true,
		// 	additionalElements: '',
		// 	idName: 'memories-slide',
		// 	video: false,
		// 	videoLinkMP4: '',
		// 	videoLinkWebM: '',
		// 	leftAligned: true,
		// 	anchorText: '',
        // },
        // {
        //     caption: 'Join us as we celebrate 40 years of love and trust',
        //     link: '/en/celebrating-40-years/',
        //     ibmClickTag: "'HP Top Carousel-_-Curacao Now Open for Booking-_-N/A'",
        //     imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_UK_40_hpbanner.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_UK_40_hpbanner.jpg',
        //     topText: '',
        //     bottomText: '',
        //     customizeLayout: true,
        //     additionalElements: '',
        //     idName: 'scr-slide',
        //     video: false,
        //     videoLinkMP4: '',
        //     videoLinkWebM: '',
        //     leftAligned: true,
        //     anchorText: '',
        // },
        // {
        //    caption: 'Sandals Holiday Assurance',
        //    link: '/en/holiday-assurance/',
        //    ibmClickTag: "'HP Top Carousel-_-Curacao Now Open for Booking-_-N/A'",
        //    imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_UK_SHA_hpbanner.jpg',
        //    imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_UK_SHA_hpbanner.jpg',
        //    topText: '',
        //    bottomText: '',
        //    customizeLayout: true,
        //    additionalElements: '',
        //    idName: 'scr-slide',
        //    video: false,
        //    videoLinkMP4: '',
        //    videoLinkWebM: '',
        //    leftAligned: true,
        //    anchorText: '',
        // },


        {
            caption: "Sandals Resorts and the Sandals Foundation partner with Ajax to launch Future Goals.",
            link: '/en/futuregoals/',
            ibmClickTag: "'HP Top Carousel-_-Sandals Future Goals-_-N/A'",
            imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/future-goals-slide/Sandals_FutureGoals_HpBanner_Mobile.jpg',
            imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/future-goals-slide/Sandals_FutureGoals_HpBanner_Desktop.jpg',
            topText: "",
            bottomText: "",
            customizeLayout: true,
            additionalElements: '',
            idName: 'future-goals-slide',
            video: false,
            videoLinkMP4: '',
            videoLinkWebM: '',
            leftAligned: false,
            anchorText: '',
        },
        // {
        //     caption: 'All Inclusive Resorts & Caribbean Holiday Packages | Sandals',
        //     link: '/en/all-inclusive/',
        //     imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---Luxury-Included-m.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---Luxury-Included.jpg',
        //     topText: '',
        //     bottomText: '',
        //     customizeLayout: true,
        //     additionalElements: '',
        //     idName: 'secondSlide',
        //     video: false,
        //     videoLinkMP4: '',
        //     videoLinkWebM: '',
        //     leftAligned: true,
        //     anchorText: '',
        // },
        // {
        //     caption: 'All Inclusive Resorts & Caribbean Holiday Packages | Sandals',
        //     link: '/en/all-inclusive/',
        //     imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---Luxury-Included-m.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---Luxury-Included.jpg',
        //     topText: '',
        //     bottomText: '',
        //     customizeLayout: true,
        //     additionalElements: '',
        //     idName: 'secondSlide',
        //     video: false,
        //     videoLinkMP4: '',
        //     videoLinkWebM: '',
        //     leftAligned: true,
        //     anchorText: '',
        // },
        // {
        //     caption: 'Caribbean Destination Wedding and Honeymoon Packages | Sandals',
        //     link: '/en/weddings/',
        //     imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---Weddings-m.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---Weddings.jpg',
        //     topText: '',
        //     bottomText: '',
        //     customizeLayout: true,
        //     additionalElements: '',
        //     idName: 'thirdSlide',
        //     video: false,
        //     videoLinkMP4: '',
        //     videoLinkWebM: '',
        //     leftAligned: true,
        //     anchorText: '',
        // },
        {
            caption: 'Accommodations at Our Luxury Resorts | Sandals',
            link: '/en/all-inclusive/accommodations/',
            imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---accomodation.jpg',
            imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---accomodation.jpg',
            topText: '',
            bottomText: '',
            customizeLayout: true,
            additionalElements: '',
            idName: 'fourthSlide',
            video: false,
            videoLinkMP4: '',
            videoLinkWebM: '',
            leftAligned: true,
            anchorText: '',
        },
        // {
        //     caption: 'Best Beaches in the Caribbean Found at Our Resorts | Sandals',
        //     link: '/en/all-inclusive/best-beaches/',
        //     imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---Beaches.jpg',
        //     imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/Sandals_EU-Web-Banners---Beaches.jpg',
        //     topText: '',
        //     bottomText: '',
        //     customizeLayout: true,
        //     additionalElements: '',
        //     idName: 'fifthSlide',
        //     video: false,
        //     videoLinkMP4: '',
        //     videoLinkWebM: '',
        //     leftAligned: true,
        //     anchorText: '',
        // },
        {
            caption: 'SANDALS® Island All-Inclusive Dining In The Bahamas',
            link: '/en/royal-bahamian/dine-out-nassau/',
            imgMobile: '//cdn.sandals.com/sandals/v12/images/home/top-slides/srb-dining-Mobile_1.jpg',
            imgDesktop: '//cdn.sandals.com/sandals/v12/images/home/top-slides/srb-dining-Desktop.jpg',
            topText: '',
            bottomText: '',
            customizeLayout: true,
            additionalElements: '',
            idName: 'sixthSlide',
            video: false,
            videoLinkMP4: '',
            videoLinkWebM: '',
            leftAligned: true,
            anchorText: ''
        }
    ]
};

module.exports = homeTopSlides;
