const newFeatures = {
    feature: [
        {
            header: 'Sandals South Coast',
            subHeader: 'Our Newly Reimagined Resort In Jamaica',
            paragraph: 'From Over&dash;The&dash;Water Bungalows, an overwater bar and a romantic chapel perched above turquoise waters, to floating bar boats and Jamaica\'s largest pool ‐ Sandals South Coast brings guests closer than ever to the water. Nestled along a pristine 2&dash;mile beach and tucked into a 500&dash;acre nature preserve, this spectacular seaside oasis is Jamaica\'s only all luxury, all beachfront resort. And now, guests can even enjoy Sushi on the Sand &dash; a brand new sushi restaurant that brings Far East fare to the Caribbean.',
            tag: '',
            link: '<a href=\'/en/south-coast/\'>View Resort <i class=\'ic ic-link-arrow\'></i></a>',
            alt: 'Sandals South Coast - Over The Water Villas',
            img: '//cdn.sandals.com/sandals/v12/images/home/whats-new/sandals-south-coast-over-water-villas-heart.jpg'
        },
        {
            header: 'Beachfront Super Butler Suites',
            subHeader: 'All-New',
            paragraph: 'These new Love Nest<sup>&reg;</sup> Suites offer direct access to the resort\'s swim-up river pool and feature wall-to-wall doors that showcase stunning views of the Caribbean Sea. With Sandals signature Tranquility Soaking Tubs™ for two in every suite and personal Butler Service, these luxurious suites are perfect for couples in love.',
            tag: 'At Sandals Montego Bay',
            link: '<a href=\'/en/montego-bay/\'>View Resort <i class=\'ic ic-link-arrow\'></i></a>',
            alt: 'Sandals Montego Bay - Beachfront Butler Suites',
            img: '//cdn.sandals.com/sandals/v12/images/home/whats-new/beachfront-super-butler-suites.jpg'
        },
        {
            header: 'Sandals Royal Barbados',
            subHeader: 'All-New, All-Suite',
            paragraph: 'Experience the royal treatment at Sandals Royal Barbados, the all-new, all-suite resort featuring Sandals first rooftop pool and bar, first 4-lane bowling alley, and first men\'s only barbershop. Plus, five world-class restaurants, top-tier suites including Skypool Suites, Swim-up Suites and Millionaire Suites, and exclusive exchange privileges with nearby Sandals Barbados.',
            tag: 'Now Open',
            link: '<a href=\'/en/royal-barbados/\'>View Resort <i class=\'ic ic-link-arrow\'></i></a>',
            alt: 'Sandals Royal Barbados - All-New All Suite',
            img: '//cdn.sandals.com/sandals/v12/images/home/whats-new/sandals-royal-barbados-pool.jpg'
        }
    ]
};

module.exports = newFeatures;
