const dotsNavigation = module.exports = (dotsWrap, $slickSlider, event, slick, currentSlide, nextSlide) => {

    function rotateDots(list, next, shiftRight = true) {

        const dots = Array.from(list.querySelectorAll('li'));
        const noOfDots = dots.length;

        dots.forEach((item) => {
            item.classList.remove('slick-active');
        });

        const dotsArr = [];

        dots.forEach((item) => {
            dotsArr.push(item.getAttribute('class'));
        });

        if(shiftRight) {
            if(dotsArr[0] === 'large' && next > 2) {
                dotsArr.unshift('semi');
                dotsArr.pop();
            } else if(dotsArr[0] === 'semi' && dotsArr[next] !== 'large') {
                dotsArr.unshift('small');
                dotsArr.pop();
            } else if(next < noOfDots && next > 2 && dotsArr[next] !== 'large') {
                dotsArr.unshift('none');
                dotsArr.pop();
            }
        } else {
            if(dotsArr[noOfDots - 1] === 'large' && next <= (noOfDots - 4)) {
                dotsArr.push('semi');
                dotsArr.shift();
            } else if(dotsArr[noOfDots - 1] === 'semi' && dotsArr[next] !== 'large') {
                dotsArr.push('small');
                dotsArr.shift();
            } else if(next >= 0 && next <= (noOfDots - 4) && dotsArr[0] !== 'large' && dotsArr[next] !== 'large') {
                dotsArr.push('none');
                dotsArr.shift();
            }
        }

        dots.forEach((item) => {
            item.setAttribute('class', '');
        });

        dots.forEach((item, index) => {
            item.classList.add(dotsArr[index]);
            if(index === (next)) {
                item.classList.add('slick-active');
            }
        });
    }

    let direction;

    const dots = Array.from(dotsWrap.querySelectorAll('li'));

    dots.map(dot => {dot.firstChild.disabled = true;});

    if (Math.abs(nextSlide - currentSlide) === 1) {
        direction = (nextSlide - currentSlide > 0) ? 'right' : 'left';
    } else {
        direction = (nextSlide - currentSlide > 0) ? 'left' : 'right';
    }

    if (direction === 'right') {
        if (nextSlide + 1 === 1) {
            dots.forEach((dot, index) => {
                dot.className = '';
                if (index <= 2) {
                    dot.classList.add('large');
                } else if (index === 3) {
                    dot.classList.add('semi');
                } else if (index === 4) {
                    dot.classList.add('small');
                } else {
                    dot.classList.add('none');
                }
            });
        } else {
            rotateDots(dotsWrap, nextSlide, true);
        }
    } else {
        if (currentSlide === 0) {
            dots.forEach((dot, index) => {
                dot.className = '';
                if (index >= (dots.length - 3)) {
                    dot.classList.add('large');
                } else if (index === (dots.length - 4)) {
                    dot.classList.add('semi');
                } else if (index === (dots.length - 5)) {
                    dot.classList.add('small');
                } else {
                    dot.classList.add('none');
                }
            });
        } else {
            rotateDots(dotsWrap, nextSlide, false);
        }
    }
};
