const instagramDots = module.exports = (dotsWrap, $slickSlider) => {

    $slickSlider.on('init', () => {
        const dots = Array.from(dotsWrap.querySelectorAll('li'));
  
        dots.map(dot => dot.firstChild.disabled = true); 
  
        return true;
    });
  
    $slickSlider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        let direction;
  
        const activeDots = Array.from(dotsWrap.querySelectorAll('.large, .semi, .small'));
        const activeDot = dotsWrap.querySelector('.slick-active');
        const dots = Array.from(dotsWrap.querySelectorAll('li'));
  
        dots.map(dot => dot.firstChild.disabled = true);
  
        if (Math.abs(nextSlide - currentSlide) === 1) {
            direction = (nextSlide - currentSlide > 0) ? 'right' : 'left';
        } else {
            direction = (nextSlide - currentSlide > 0) ? 'left' : 'right';
        }
  
        if (direction === 'right') {
            if (nextSlide + 1 === 1) {
                dots.forEach((dot, index) => {
                    dot.className = '';
                    if (index <= 2) {
                        dot.classList.add('large');
                    } else if (index === 3) {
                        dot.classList.add('semi');
                    } else if (index === 4) {
                        dot.classList.add('small');
                    } else {
                        dot.classList.add('none');
                    }
                });
            } else if (activeDot.nextElementSibling.classList.contains('semi')) {
                activeDots[activeDots.length - 1].classList.remove('small');
                activeDots[activeDots.length - 1].classList.add('semi');
                if (nextSlide === slick.slideCount - 2 && dots.length !== 6) {
                    activeDots[activeDots.length - 2].classList.remove('semi');
                    activeDots[activeDots.length - 2].classList.add('large');
                    activeDots[0].classList.remove('small');
                    activeDots[0].classList.add('none');
                    activeDots[1].classList.remove('semi');
                    activeDots[1].classList.add('small');
                    activeDots[2].classList.remove('large');
                    activeDots[2].classList.add('semi');
                } else if (nextSlide === slick.slideCount - 1 && dots.length !== 6) {
                    activeDots[activeDots.length - 1].classList.remove('semi');
                    activeDots[activeDots.length - 1].classList.add('large');
                    activeDots[0].classList.remove('small');
                    activeDots[0].classList.add('none');
                    activeDots[1].classList.remove('semi');
                    activeDots[1].classList.add('small');
                    activeDots[2].classList.remove('large');
                    activeDots[2].classList.add('semi');
                } else if (nextSlide === slick.slideCount - 1 && dots.length === 6) {
                    activeDots[activeDots.length - 1].classList.remove('semi');
                    activeDots[activeDots.length - 1].classList.add('large');
                    activeDots[0].classList.remove('small');
                    activeDots[0].classList.add('none');
                    activeDots[1].classList.remove('semi');
                    activeDots[1].classList.add('small');
                    activeDots[2].classList.remove('large');
                    activeDots[2].classList.add('semi');
                } else if (nextSlide === slick.slideCount - 2 && dots.length === 6) {
                    activeDots[activeDots.length - 2].classList.remove('semi');
                    activeDots[activeDots.length - 2].classList.add('large');
                    activeDots[0].classList.remove('semi');
                    activeDots[0].classList.add('small');
                    activeDots[1].classList.remove('large');
                    activeDots[1].classList.add('semi');
                } else {
                    if (activeDots[0].classList.contains('large')) {
                        activeDots[0].classList.remove('large');
                        activeDots[0].classList.add('semi');
                    } else if (activeDots[0].classList.contains('semi')) {
                        activeDots[1].classList.remove('large');
                        activeDots[1].classList.add('semi');
                        activeDots[0].classList.remove('semi');
                        activeDots[0].classList.add('small');
                    } else if (activeDots[0].classList.contains('small')) {
                        activeDots[2].classList.remove('large');
                        activeDots[2].classList.add('semi');
                        activeDots[1].classList.remove('semi');
                        activeDots[1].classList.add('small');
                        activeDots[0].classList.remove('small');
                        activeDots[0].classList.add('none');
                    }
                    activeDot.nextElementSibling.classList.remove('semi');
                    activeDot.nextElementSibling.classList.add('large');
  
                    const semiDots = dotsWrap.getElementsByClassName('semi');
                    const lastSemiDot = semiDots[semiDots.length - 1];
  
                    lastSemiDot.nextElementSibling.classList.remove('none');
                    lastSemiDot.nextElementSibling.classList.add('small');
                }
            }
        } else {
            if (currentSlide === 0) {
                dots.forEach((dot, index) => {
                    dot.className = '';
                    if (index >= (dots.length - 3)) {
                        dot.classList.add('large');
                    } else if (index === (dots.length - 4)) {
                        dot.classList.add('semi');
                    } else if (index === (dots.length - 5)) {
                        dot.classList.add('small');
                    } else {
                        dot.classList.add('none');
                    }
                });
            } else if (activeDot.previousElementSibling.classList.contains('semi')) {
                if (currentSlide === 1) {
                    activeDots[activeDots.length - 2].classList.remove('semi');
                    activeDots[activeDots.length - 2].classList.add('small');
                    activeDots[0].classList.remove('semi');
                    activeDots[0].classList.add('large');
                    activeDots[3].classList.remove('large');
                    activeDots[3].classList.add('semi');
                    activeDots[activeDots.length - 1].classList.remove('small');
                    activeDots[activeDots.length - 1].classList.add('none');
                } else if (currentSlide === 2 && dots.length !== 6) {
                    activeDots[activeDots.length - 2].classList.remove('semi');
                    activeDots[activeDots.length - 2].classList.add('small');
                    activeDots[activeDots.length - 3].classList.remove('large');
                    activeDots[activeDots.length - 3].classList.add('semi');
                    activeDots[0].classList.remove('small');
                    activeDots[0].classList.add('semi');
                    activeDots[1].classList.remove('semi');
                    activeDots[1].classList.add('large');
                    activeDots[activeDots.length - 1].classList.remove('small');
                    activeDots[activeDots.length - 1].classList.add('none');
                } else if (currentSlide === 2 && dots.length === 6) {
                    activeDots[0].classList.remove('small');
                    activeDots[0].classList.add('semi');
                    activeDots[1].classList.remove('semi');
                    activeDots[1].classList.add('large');
                    activeDots[activeDots.length - 1].classList.remove('semi');
                    activeDots[activeDots.length - 1].classList.add('small');
                    activeDots[activeDots.length - 2].classList.remove('large');
                    activeDots[activeDots.length - 2].classList.add('semi');
                } else if (currentSlide === 1 && dots.length === 6) {
                    activeDots[0].classList.remove('small');
                    activeDots[0].classList.add('semi');
                    activeDots[1].classList.remove('semi');
                    activeDots[1].classList.add('large');
                    activeDots[activeDots.length - 1].classList.remove('semi');
                    activeDots[activeDots.length - 1].classList.add('small');
                    activeDots[activeDots.length - 2].classList.remove('large');
                    activeDots[activeDots.length - 2].classList.add('semi');
                } else {
                    if (activeDots.length === 7) {
                        if (activeDots[0].classList.contains('small')) {
                            activeDots[1].classList.remove('semi');
                            activeDots[1].classList.add('large');
                            activeDots[0].classList.remove('small');
                            activeDots[0].classList.add('semi');
                            activeDots[0].previousElementSibling.classList.remove('none');
                            activeDots[0].previousElementSibling.classList.add('small');
                            activeDots[activeDots.length - 1].classList.remove('small');
                            activeDots[activeDots.length - 1].classList.add('none');
                            activeDots[activeDots.length - 2].classList.remove('semi');
                            activeDots[activeDots.length - 2].classList.add('small');
                            activeDots[activeDots.length - 3].classList.remove('large');
                            activeDots[activeDots.length - 3].classList.add('semi');
                        } else if (activeDots[0].classList.contains('semi')) {
                            activeDots[0].classList.remove('semi');
                            activeDots[0].classList.add('large');
                            activeDots[0].previousElementSibling.classList.remove('none');
                            activeDots[0].previousElementSibling.classList.add('small');
                            activeDots[activeDots.length - 1].classList.remove('semi');
                            activeDots[activeDots.length - 1].classList.add('small');
                        }
                    } else if (activeDots.length === 6) {
                        if (activeDots[0].classList.contains('semi')) {
                            activeDots[0].classList.remove('semi');
                            activeDots[0].classList.add('large');
                            activeDots[0].previousElementSibling.classList.remove('none');
                            activeDots[0].previousElementSibling.classList.add('small');
                            activeDots[activeDots.length - 1].classList.remove('small');
                            activeDots[activeDots.length - 1].classList.add('none');
                            activeDots[activeDots.length - 2].classList.remove('semi');
                            activeDots[activeDots.length - 2].classList.add('small');
                            activeDots[activeDots.length - 3].classList.remove('large');
                            activeDots[activeDots.length - 3].classList.add('semi');
                        } else if (activeDots[0].classList.contains('small')) {
                            activeDots[0].classList.remove('small');
                            activeDots[0].classList.add('semi');
                            activeDots[1].classList.remove('semi');
                            activeDots[1].classList.add('large');
                            activeDots[0].previousElementSibling.classList.remove('none');
                            activeDots[0].previousElementSibling.classList.add('small');
                            activeDots[activeDots.length - 2].classList.remove('large');
                            activeDots[activeDots.length - 2].classList.add('semi');
                            activeDots[activeDots.length - 1].classList.remove('semi');
                            activeDots[activeDots.length - 1].classList.add('small');
                        }
                    } else if (activeDots.length === 5) {
                        if (activeDots[0].classList.contains('small')) {
                            activeDots[1].classList.remove('semi');
                            activeDots[1].classList.add('large');
                            activeDots[0].classList.remove('small');
                            activeDots[0].classList.add('semi');
                            activeDots[0].previousElementSibling.classList.remove('none');
                            activeDots[0].previousElementSibling.classList.add('small');
                            activeDots[activeDots.length - 1].classList.remove('large');
                            activeDots[activeDots.length - 1].classList.add('semi');
                        } else if (activeDots[0].classList.contains('semi')) {
                            activeDots[0].classList.remove('semi');
                            activeDots[0].classList.add('large');
                            activeDots[0].previousElementSibling.classList.remove('none');
                            activeDots[0].previousElementSibling.classList.add('small');
                            activeDots[activeDots.length - 1].classList.remove('semi');
                            activeDots[activeDots.length - 1].classList.add('small');
                        }
                    }
                }
            }
        }
    });
  };