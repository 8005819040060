const inclusions = {
    inclusion: [
        {
            top: '#1 All-Inclusive',
            bottom: 'Dining Experience',
            included: 'Included',
            logo: '//cdn.sandals.com/sandals/v12/images/home/included-slider/element-globalGourmet.png',
            alt: 'All-Inclusive Dining',
            img: '//cdn.sandals.com/sandals/v12/images/home/included-slider/global-gourmet.jpg',
            link: '/en/all-inclusive/dining/'
        },
        {
            top: 'Unlimited',
            bottom: 'Premium Spirits',
            included: 'Included',
            logo: '//cdn.sandals.com/sandals/v12/images/home/included-slider/element-drinksLogos.png',
            alt: 'Premium Spirits',
            img: '//cdn.sandals.com/sandals/v12/images/home/included-slider/premium-spirits.jpg',
            link: '/en/all-inclusive/bars/'
        },
        {
            top: 'Padi<sup>&reg;</sup> Certified',
            bottom: 'Scuba Diving',
            included: 'Included',
            logo: '//cdn.sandals.com/sandals/v12/images/home/included-slider/element-scubaTop5.png',
            alt: 'Padi<sup>&reg;</sup> Scuba Diving',
            img: '//cdn.sandals.com/sandals/v12/images/home/included-slider/padi-scuba.jpg',
            link: '/en/scuba/'
        },
        {
            top: 'Unlimited',
            bottom: 'Water Sports',
            included: 'Included',
            logo: '//cdn.sandals.com/sandals/v12/images/home/included-slider/element-aquaCenter.png',
            alt: 'Unlimited Water Sports',
            img: '//cdn.sandals.com/sandals/v12/images/home/included-slider/aqua-center.jpg',
            link: '/en/all-inclusive/watersports/'
        },
        {
            top: 'Unlimited',
            bottom: 'Land Sports &amp; Fitness',
            included: 'Included',
            logo: '',
            alt: 'Unlimited Land Sports &amp; Fitness',
            img: '//cdn.sandals.com/sandals/v12/images/home/included-slider/land-sports.jpg',
            link: '/en/all-inclusive/landsports/'
        },
        {
            top: 'Award-Winning',
            bottom: 'Championship Golf',
            included: 'Green Fees Included',
            logo: '//cdn.sandals.com/sandals/v12/images/home/included-slider/element-golfLogos.png',
            alt: 'Sandals Golf',
            img: '//cdn.sandals.com/sandals/v12/images/home/included-slider/sandals-golf.jpg',
            link: '/en/golf/'
        },
        {
            top: 'And',
            bottom: 'Much More...',
            included: '',
            logo: '//cdn.sandals.com/sandals/v12/images/home/included-slider/element-5Star.png',
            alt: 'Sandals Luxury All-Inclusive Resorts',
            img: '//cdn.sandals.com/sandals/v12/images/home/included-slider/couple-dancing.jpg',
            link: '/en/all-inclusive/'
        }
    ]
};

module.exports = inclusions;
