const suites = {
    suite: [
        {
            suite: 'Spectacular Over-The-Water Villa',
            resort: 'Royal Caribbean',
            link: '/en/royal-caribbean/',
            img: '//cdn.sandals.com/sandals/v12/images/home/suite-slider/over-the-water-villa.jpg'
        },
        {
            suite: 'Skypool Butler Suite',
            resort: 'Royal Barbados',
            link: '/en/royal-barbados/',
            img: '//cdn.sandals.com/sandals/v12/images/home/suite-slider/skypool-butler-suite.jpg'
        },
        {
            suite: 'Private Pool Sanctuary Butler Villa',
            resort: 'Grenada',
            link: '/en/sandals-grenada/',
            img: '//cdn.sandals.com/sandals/v12/images/home/suite-slider/private-pool-sanctuary-butler-villa.jpg'
        },
        {
            suite: 'Swim-up Super Luxe Butler Suite',
            resort: 'Montego Bay',
            link: '/en/montego-bay/',
            img: '//cdn.sandals.com/sandals/v12/images/home/suite-slider/swim-up-super-luxe-butler-suite.jpg'
        },
        {
            suite: 'Millionaire Butler Villa',
            resort: 'Regency La Toc',
            link: '/en/regency-la-toc/',
            img: '//cdn.sandals.com/sandals/v12/images/home/suite-slider/millionaire-butler-villa.jpg'
        },
        {
            suite: 'Beachfront Rondoval Butler Suite',
            resort: 'Grande St. Lucian',
            link: '/en/grande-st-lucian/',
            img: '//cdn.sandals.com/sandals/v12/images/home/suite-slider/beachfront-rondoval-butler-suite.jpg'
        }
    ]
};

module.exports = suites;
